<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-4 ml-3"
        style="font-size: 26px; align-items: center; display: flex;"
      >
        <v-icon right style="margin-right: 14px; color: #625fa4;"
          >mdi-newspaper</v-icon
        >
        Crear factura
      </div>
      <v-col cols="12">
        <alert
          :data="errorData"
          :active="activeAlert"
          :colorAlert="colorAlert"
        />
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-text>
              <v-form>
                <div
                  style="font-size: 21px; font-weight: 500; margin-bottom: 4px;"
                >
                  Datos generales
                </div>
                <div style="font-size: 14px; margin-bottom: 20px;">
                  Acá van los datos más generales de la factura.
                </div>
                <v-row dense>
                  <v-col cols="12" sm="8" md="8">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        :items="provider"
                        item-text="value.label"
                        item-value="value"
                        :loading="searchLoading"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        role="button"
                        label="Proveedor/Cliente"
                        name="provider"
                        color="blue-grey"
                        v-model="clientSelected"
                        :search-input.sync="search"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" v-if="clientSelected">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="voucherOptions"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Tipo de comprobante"
                        name="type_voucher"
                        color="blue-grey"
                        v-model="selectedVoucher"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" v-if="clientSelected">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="mode"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Modo"
                        name="mode"
                        color="blue-grey"
                        v-model="selectedMode"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" v-if="clientSelected">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="condition_sale"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Condición de venta"
                        name="condition_sale"
                        color="blue-grey"
                        v-model="selectedConditionSale"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" v-if="clientSelected">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="branchOptions"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Sucursal"
                        name="branch"
                        color="blue-grey"
                        v-model="selectedBranch"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" v-if="clientSelected">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="warehouse"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Bodega"
                        name="warehouse"
                        color="blue-grey"
                        v-model="selectedWharehouse"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <!-- <v-col cols="12" sm="4" md="4" v-if="clientSelected">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="seller"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Vendedor"
                        name="seller"
                        color="blue-grey"
                        v-model="data.seller"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card height="auto" class="mb-5" v-if="clientSelected">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-text>
              <v-form>
                <div
                  style="font-size: 21px; font-weight: 500; margin-bottom: 4px;"
                >
                  Período a imputar
                </div>
                <div style="font-size: 14px; margin-bottom: 20px;">
                  Ingrese la fecha de emisión de la factura y la fecha de
                  vencimiento del cobro de la factura.
                </div>
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu_open"
                      v-model="menu_open"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name=" "
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            v-model="opening_date"
                            label="Fecha"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-if="menu_open"
                        v-model="picker_open_date"
                        full-width
                        @input="menu_open = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu_close"
                      v-model="menu_close"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name=" "
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            v-model="close_date"
                            label="Vencimiento de cobro"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-if="menu_close"
                        v-model="picker_close_date"
                        full-width
                        @input="menu_close = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu_close"
                      v-model="menu_close"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name=" "
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            v-model="close_date"
                            label="Vencimiento de cobro"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-if="menu_close"
                        v-model="close_date"
                        full-width
                        @input="menu_close = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card height="auto" class="mb-5" v-if="clientSelected">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-text>
              <v-form>
                <div
                  style="font-size: 21px; font-weight: 500; margin-bottom: 4px;"
                >
                  Items a incluir
                </div>
                <div style="font-size: 14px; margin-bottom: 20px;">
                  Agrega a continuación los productos o servicios que desea
                  incluir.
                </div>
                <v-row dense :key="resetKey">
                  <v-col cols="12" sm="2" md="2">
                    <ValidationProvider
                      name=" "
                      rules="required|numeric"
                      v-slot="{ errors }"
                      ref="quantityValidation"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Cantidad"
                        name="product_quantity"
                        color="blue-grey"
                        v-model="product_quantity"
                        :error-messages="errors"
                        maxlength="5"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="itemValidation"
                    >
                      <v-autocomplete
                        :items="!inEditingAction ? items : productsInTable"
                        :loading="searchLoading"
                        item-text="value.label"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        :disabled="inEditingAction"
                        role="button"
                        label="Seleccione un item"
                        name="item"
                        color="blue-grey"
                        v-model="selectedItem"
                        :search-input.sync="searchItem"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="priceValidation"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        :label="unitPriceText"
                        name="price"
                        color="blue-grey"
                        :value="
                          countryID === 2
                            ? selectedItem.PrecioFinal
                            : selectedItem.Precio
                        "
                        @input="updateSelectedPrice"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <!-- <v-col cols="12" sm="2" md="2">
                    <ValidationProvider
                      name=" "
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Bonif %"
                        name="bonus"
                        color="blue-grey"
                        v-model="data.bonus"
                        :error-messages="errors"
                        maxlength="2"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col> -->
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="accountValidation"
                    >
                      <v-autocomplete
                        :items="accountOptions"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        role="button"
                        label="Cuenta contable"
                        name="account"
                        color="blue-grey"
                        v-model="selectedAccount"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="descriptionValidation"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Ingrese una descripción libre"
                        name="description"
                        color="blue-grey"
                        v-model="selectedItem.Nombre"
                        :error-messages="errors"
                        maxlength="50"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-row style="padding: 0 14px 0 14px">
                      <v-hover v-slot:default="{ hover }">
                        <v-btn
                          text
                          rounded
                          class="mr-2"
                          :color="hover ? 'error' : 'grey'"
                          @click="HandlerClearItem"
                          >Cancelar</v-btn
                        >
                      </v-hover>

                      <v-hover v-slot:default="{ hover }">
                        <v-btn
                          rounded
                          :loading="loading"
                          :disabled="loading"
                          color="success"
                          :class="hover ? 'shadow-2' : 'shadow-4'"
                          class="px-8 mx-2"
                          @click="HandlerTableItems"
                          >{{ inEditingAction ? "EDITAR" : "AGREGAR" }}
                        </v-btn>
                      </v-hover>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="px-0 py-0 mt-6 rounded-small"
                      style="background-color: rgba(0,0,0,.06);"
                    >
                      <v-data-table
                        :loading="false"
                        :headers="headers"
                        :items="tableItems"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                        :page.sync="page"
                        @page-count="pageCount = $event"
                        style="background-color:transparent !important;"
                        no-data-text="No tienes items agregados"
                        item-key="idTable"
                      >
                        <template v-slot:item.idTable="{ item }">
                          {{ item.idTable }}
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                          <div class="row-btn">
                            <v-btn
                              fab
                              @click="initEditItem(item)"
                              x-small
                              class="mr-2"
                              :disabled="inEditingAction"
                            >
                              <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn
                              fab
                              @click="deleteItem(item)"
                              x-small
                              :disabled="inEditingAction"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-col>
                  <v-col cols="12" sm="5" md="5" class="mt-6">
                    <ValidationProvider name=" " rules="" v-slot="{ errors }">
                      <v-textarea
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Observaciones"
                        name="observations"
                        color="blue-grey"
                        v-model="observations"
                        :error-messages="errors"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="2" md="2"></v-col>
                  <v-col cols="12" sm="5" md="5" class="mt-6">
                    <div
                      style="display:flex; flex-direction:column; border: 1px solid rgba(0,0,0,.10);"
                      class="rounded-small"
                    >
                      <div
                        style="display:flex; border-bottom: 1px solid rgba(0,0,0,.10); flex-direction:row; padding: 10px 24px 10px 24px; justify-content: space-between; justify-content: space-between;"
                      >
                        <div>
                          Neto gravado:
                        </div>
                        <div>{{ currencyType }} {{ netTaxed }}</div>
                      </div>
                      <div
                        style="display:flex; border-bottom: 1px solid rgba(0,0,0,.10); flex-direction:row; padding: 10px 24px 10px 24px; justify-content: space-between;"
                      >
                        <div>
                          NO gravado/Exento:
                        </div>
                        <div>{{ currencyType }} {{ exempt }}</div>
                      </div>
                      <div
                        style="display:flex; border-bottom: 1px solid rgba(0,0,0,.10); flex-direction:row; padding: 10px 24px 10px 24px; justify-content: space-between;"
                      >
                        <div>
                          IVA:
                        </div>
                        <div>{{ currencyType }} {{ ivaReport }}</div>
                      </div>
                      <div
                        style="display:flex; flex-direction:row; padding: 10px 24px 10px 24px; justify-content: space-between;"
                      >
                        <div>
                          TOTAL:
                        </div>
                        <div>{{ currencyType }} {{ total }}</div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-form>
            </v-card-text>
            <v-card-text class="pt-0 pb-8">
              <div>
                <div style="display:flex; flex-direction:row;">
                  <div style="margin-right:6px;">Usuario alta:</div>
                  <div>grandflex@mail.com</div>
                </div>
                <div style="display:flex; flex-direction:row;">
                  <div style="margin-right:6px;">Usuario modificada:</div>
                  <div></div>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-2"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loadingEmmiting"
                  :disabled="loadingEmmiting"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8 mx-2"
                  @click="passes(EmitInvoice)"
                  >{{
                    action == 1 ? "Editar" : "Emitir electrónicamente"
                  }}</v-btn
                >
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
        <!-- </v-dialog> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import alert from "../Utils/Alert";
import axios from "axios";
import moment from "moment";
export default {
  components: {
    alert,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    country: {
      type: Array,
      required: false,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: true,
      default: () => {},
    },
    countryID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      provider: [],
      clientSelected: null,
      opening_date: moment().format("DD/MM/YYYY"),
      observations: "",
      close_date: moment()
        .add(1, "month")
        .add(-1, "days")
        .format("DD/MM/YYYY"),
      picker_close_date: moment()
        .add(1, "month")
        .add(-1, "days")
        .format("YYYY-MM-DD"),
      picker_open_date: moment().format("YYYY-MM-DD"),
      selectedVoucher: "",
      selectedBranch: "",
      voucherOptions: [],
      branchOptions: [],
      accountOptions: [],
      unitPriceText: "",
      currencyType: this.countryID === 1 ? "ARS" : "CLP",
      type_cf_voucher: [
        {
          title: "Boleta",
          value: "BOL",
        },
        {
          title: "Nota de crédito",
          value: "NCF",
        },
        {
          title: "Nota de débito",
          value: "NDF",
        },
        {
          title: "Voucher interno",
          value: "VOI",
        },
        {
          title: "Nota de crédito VOI",
          value: "NCV",
        },
      ],
      type_voucher: [
        {
          title: "Factura",
          value: "FAC",
        },
        {
          title: "Factura no afecto o exenta",
          value: "FEX",
        },
        {
          title: "Nota de crédito",
          value: "NCF",
        },
        {
          title: "Nota de débito",
          value: "NDF",
        },
        {
          title: "Voucher interno",
          value: "VOI",
        },
        {
          title: "Nota de crédito VOI",
          value: "NCV",
        },
      ],
      type_ri_voucher_arg: [
        {
          title: "Factura A",
          value: "FCA",
        },
        {
          title: "Nota débito A",
          value: "NDA",
        },
        {
          title: "Factura B",
          value: "FCB",
        },
        {
          title: "Nota débito B",
          value: "NDB",
        },
        {
          title: "Factura M",
          value: "FCM",
        },
        {
          title: "Nota débito M",
          value: "NDM",
        },{
          title: "Nota crédito A",
          value: "NCA",
        },{
          title: "Nota crédito B",
          value: "NCB",
        },{
          title: "Nota crédito M",
          value: "NCM",
        },{
          title: "Cotización",
          value: "COT",
        },{
          title: "Nota crédito COT",
          value: "NCT",
        },
      ],
      type_voucher_arg: [
        {
          title: "Factura B",
          value: "FCB",
        },
        {
          title: "Nota débito B",
          value: "NDB",
        },
        {
          title: "Nota crédito B",
          value: "NCB",
        },
        {
          title: "Cotización",
          value: "COT",
        },
        {
          title: "Nota de crédito COT",
          value: "NCT",
        },
      ],
      selectedMode: "E",
      mode: [
        {
          title: "Talonario preimpreso",
          value: "T",
        },
        {
          title: "Electrónica",
          value: "E",
        },
      ],
      selectedConditionSale: {},
      condition_sale: [],
      branch: [
        {
          title: "0001",
          value: 1120,
        },
      ],
      branch_arg: [
        {
          title: "0012",
          value: 7689,
        },
        {
          title: "0001",
          value: 7222,
        },
        {
          title: "0008",
          value: 7690,
        },
        {
          title: "0013",
          value: 7691,
        },
        {
          title: "0014",
          value: 7692,
        },
      ],
      selectedWharehouse: {},
      warehouse: [],
      seller: [
        {
          title: "grandflex@mail.com",
          value: "grandflex@mail.com",
        },
      ],
      sales_order: [
        {
          title: "000001",
          value: "000001",
        },
        {
          title: "000002",
          value: "000002",
        },
        {
          title: "000003",
          value: "000003",
        },
      ],
      selectedItem: {},
      items: [],
      defaultIva: {
        title: "GRAVADO A TASA BÁSICA",
        value: 20,
      },
      iva: [
        {
          title: "GRAVADO A TASA BÁSICA",
          value: 20,
        },
      ],
      selectedAccount: "",
      accounts: [
        {
          title: "4.1.1 - Ventas",
          value: "4.1.1 - Ventas",
        },
        {
          title: "4.1.2 - Ingresos por servicios",
          value: "4.1.2 - Ingresos por servicios",
        },
      ],
      accounts_arg: [
        {
          title: "4.1.1 - VENTAS NACIONALES",
          value: "4.1.1 - VENTAS NACIONALES",
        },
        {
          title: "4.1.3 - INGRESOS POR FLETES",
          value: "4.1.3 - INGRESOS POR FLETES",
        },
      ],
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "idTable",
          class: "primary--text",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "product_quantity",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Código",
          sortable: false,
          value: "code",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Descripción",
          sortable: false,
          value: "description",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Precio unit. sin IVA",
          sortable: false,
          value: "priceTable",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Bonif. %",
          sortable: false,
          value: "bonus",
          align: "center",
          class: "primary--text",
        },
        {
          text: "IVA %",
          sortable: false,
          value: "ivaTable",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Cuenta",
          sortable: false,
          value: "account",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Subtotal",
          sortable: false,
          value: "subtotal",
          align: "center",
          class: "primary--text",
        },
        {
          text: "",
          sortable: false,
          value: "action",
          align: "center",
          class: "primary--text",
        },
      ],
      tableItems: [],
      country_id: "",
      product_quantity: 1,
      productsInTable: [],
      inEditingAction: false,
      rowNumberTable: 1,
      itemToEdit: {},
      state_id: "",
      location_id: [],
      stateData: [],
      locationData: [],
      loadingState: false,
      loadingLocation: false,
      searchLoading: false,
      loadingEmmiting: false,
      itemsPerPage: 15,
      page: 1,
      menu_open: false,
      menu_close: false,
      loading: false,
      errorData: "",
      activeAlert: false,
      colorAlert: "",

      singleSelect: false,
      search: null,
      searchItem: null,
      resetKey: 0,
      netTaxed: 0,
      exempt: 0,
      ivaReport: 0,
      total: 0,
    };
  },

  created() {
    this.getSaleConditions();
    this.getWhareHouses();
  },

  watch: {
    country_id(val) {
      this.HandlerGetState(val);
    },
    state_id(val) {
      this.HandlerGetLocations(val);
    },
    picker_close_date(val) {
      this.close_date = moment(val).format("DD/MM/YYYY");
    },
    picker_open_date(val) {
      this.opening_date = moment(val).format("DD/MM/YYYY");
    },
    search(val) {
      val && val !== this.clientSelected?.label && this.getClients(val);
    },
    searchItem(val) {
      val && val !== this.selectedItem?.label && this.getItems(val);
    },
    selectedItem(val) {
      if (!this.inEditingAction) {
        if (val.Codigo === "9000") {
          if (this.countryID === 2) {
            this.selectedAccount = "4.1.2 - Ingresos por servicios";
          } else {
            this.selectedAccount = "4.1.3 - INGRESOS POR FLETES";
          }
        } else {
          if (this.countryID === 2) {
            this.selectedAccount = "4.1.1 - Ventas";
          } else {
            this.selectedAccount = "4.1.1 - VENTAS NACIONALES";
          }
        }
      }
    },
    clientSelected(val) {
      if (this.countryID === 2) {
        if (val.CondicionIva === "CF") {
          this.voucherOptions = this.type_cf_voucher;
          this.selectedVoucher = "BOL";
        } else {
          this.voucherOptions = this.type_voucher;
          this.selectedVoucher = "FAC";
        }
        this.branchOptions = this.branch;
        this.selectedBranch = 1120;
        this.accountOptions = this.accounts;
        this.unitPriceText = "Precio Unit. con IVA";
      } else {
        if(val.CondicionIva === "RI"){
          this.voucherOptions = this.type_ri_voucher_arg
          this.selectedVoucher = "FCA"
        }else{
          this.voucherOptions = this.type_voucher_arg;
          this.selectedVoucher = "FCB";
        }
        this.branchOptions = this.branch_arg;
        this.selectedBranch = 7689;
        this.accountOptions = this.accounts_arg;
        this.unitPriceText = "Precio Unit. sin IVA";
      }
    },

    tableItems(val) {
      const taxedItems = val.filter((elem) => Number(elem.ivaTable) !== 0);
      const exemptItems = val.filter((elem) => Number(elem.ivaTable) === 0);
      const netTaxedAmount = taxedItems.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          Number(currentValue.priceTable) * currentValue.product_quantity
        );
      }, 0);

      const exemptAmount = exemptItems.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          Number(currentValue.priceTable) * currentValue.product_quantity
        );
      }, 0);

      const ivaReportAmount = val.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          ((Number(currentValue.priceTable) * Number(currentValue.ivaTable)) /
            100) *
            currentValue.product_quantity
        );
      }, 0);

      const totalAmount = val.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.subtotal);
      }, 0);

      this.netTaxed = netTaxedAmount.toFixed(2);
      this.ivaReport = ivaReportAmount.toFixed(2);
      this.total = totalAmount.toFixed(2);
      this.exempt = exemptAmount.toFixed(2);
    },
  },

  methods: {
    updateSelectedPrice(value) {
      if (this.countryID === 2) {
        this.selectedItem.PrecioFinal = value;
      } else {
        this.selectedItem.Precio = value;
        this.selectedItem.PrecioFinal =
          value * (1 + this.selectedItem.Iva / 100);
      }
    },
    // closeModal() {
    //   this.$emit("dialog:change", false);
    // },

    async getClients(val) {
      this.searchLoading = true;
      const response = await axios.get(
        `https://ventas.flextd.com/api/contabilium/clients`,
        {
          params: {
            countryId: this.countryID,
            filter: val,
            page: 1,
            pageSize: 10,
          },
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
        }
      );
      const { data } = response;
      if (data.Items) {
        this.searchLoading = false;
        this.provider = data.Items.map((item) => ({
          value: { ...item, label: item.NroDoc + " - " + item.RazonSocial },
        }));
      }
    },

    async getItems(val) {
      this.searchLoading = true;
      const response = await axios.get(
        `https://ventas.flextd.com/api/contabilium/products`,
        {
          params: {
            countryId: this.countryID,
            filter: val,
            page: 1,
            pageSize: 10,
          },
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
        }
      );
      const { data } = response;
      if (data.Items) {
        this.searchLoading = false;
        this.items = data.Items.map((item) => ({
          value: { ...item, label: item.Codigo + " - " + item.Nombre },
        }));
      }
    },

    async getSaleConditions() {
      const response = await axios.get(
        "https://ventas.flextd.com/api/contabilium/sale-conditions",
        {
          params: {
            countryId: this.countryID,
          },
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
        }
      );
      const { data } = response;
      this.condition_sale = data.map(({ Nombre }) => ({
        title: Nombre,
        value: Nombre,
      }));
      if (this.countryID === 2) {
        this.selectedConditionSale = "TRANSFERENCIA";
      } else {
        this.selectedConditionSale = "CONTADO";
      }
    },
    async getWhareHouses() {
      const response = await axios.get(
        "https://ventas.flextd.com/api/contabilium/warehouses",
        {
          params: {
            countryId: this.countryID,
          },
          headers: {
            Authorization: "bearer " + localStorage.getItem("token"),
          },
        }
      );
      const { data } = response;
      this.warehouse = data.map(({ Id, Nombre }) => ({
        title: Nombre,
        value: Id,
      }));

      if (this.countryID === 2) {
        this.selectedWharehouse = 1124;
      } else {
        this.selectedWharehouse = 31467;
      }
    },

    async HandlerGetState(item) {
      try {
        this.loadingState = true;
        const request = { country_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_STATE",
          request
        );
        this.stateData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingState = false;
      }
    },

    async HandlerGetLocations(item) {
      try {
        this.loadingLocation = true;
        const request = { state_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_LOCATIONS",
          request
        );
        this.locationData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingLocation = false;
      }
    },

    HandlerTableItems() {
      this.$refs.quantityValidation.validate();
      this.$refs.itemValidation.validate();
      this.$refs.priceValidation.validate();
      this.$refs.accountValidation.validate();
      this.$refs.descriptionValidation.validate();
      if (
        this.selectedItem.Nombre &&
        this.product_quantity &&
        this.selectedItem.PrecioFinal
      ) {
        const priceTableItem =
          (Number(this.selectedItem.PrecioFinal) * 100) /
          (100 + this.selectedItem?.Iva);
        const subtotalItem =
          Number(this.selectedItem.PrecioFinal) * this.product_quantity;
        const addItem = {
          idTable: this.tableItems.length + 1,
          product_quantity: this.product_quantity,
          code: this.selectedItem.Codigo,
          description: this.selectedItem?.Nombre,
          priceTable: priceTableItem.toFixed(2),
          bonus: `0`,
          ivaTable: `${this.selectedItem?.Iva}`,
          account: this.selectedAccount,
          subtotal: subtotalItem.toFixed(2),
          id: this.selectedItem?.Id,
          costo: this.selectedItem?.CostoInterno,
        };
        const editItem = { ...addItem, idTable: this.itemToEdit.idTable };
        if (!this.inEditingAction) {
          this.productsInTable.push({
            value: {
              ...this.selectedItem,
              label:
                this.selectedItem.Codigo + " - " + this.selectedItem.Nombre,
            },
          });
          this.tableItems.push(addItem);
        } else {
          const elementIndex = this.tableItems.findIndex(function(elem) {
            return elem.code === addItem.code;
          });
          if (elementIndex !== -1) {
            this.tableItems.splice(elementIndex, 1, editItem);
          }
        }
        this.HandlerClearItem();
      }
    },

    async EmitInvoice() {
      try {
        this.loadingEmmiting = true;
        if (this.tableItems.length > 0) {
          const { TipoDoc, NroDoc, Id, RazonSocial } = this.clientSelected;
          const payload = {
            countryId: this.countryID,
            buyer: {
              Id,
              TipoDoc,
              NroDoc,
              RazonSocial,
            },
            items: this.tableItems.map(
              ({
                product_quantity,
                code,
                subtotal,
                priceTable,
                ivaTable,
                id,
                costo,
                description,
              }) => ({
                Id: id,
                Nombre: description,
                Codigo: code,
                Precio: Number(priceTable),
                PrecioFinal: Number(subtotal),
                Iva: Number(ivaTable),
                CostoInterno: costo,
                quantity: Number(product_quantity),
              })
            ),
            warehouseId: this.selectedWharehouse,
            invoiceType: this.selectedVoucher,
            saleCondition: this.selectedConditionSale,
            salePoint: this.selectedBranch,
            invoiceMode: this.selectedMode,
            observations: this.observations,
            expirationDate: this.picker_close_date,
            emissionDate: this.picker_open_date,
          };
          await axios.post(
            "https://ventas.flextd.com/api/orders/manual-invoicing",
            payload,
            {
              headers: {
                Authorization: "bearer " + localStorage.getItem("token"),
              },
            }
          );
          this.closeModal();
          this.$snotify.success("La factura se ha emitido exitosamente");
        }
      } catch (error) {
        this.$snotify.error("Comunicarse con soporte", "Error");
      } finally {
        this.loadingEmmiting = false;
      }
    },

    HandlerClearItem() {
      this.inEditingAction = false;
      this.data.amount = null;
      this.data.code = null;
      this.data.description = null;
      this.data.priceTable = null;
      this.data.bonus = null;
      this.data.ivaTable = null;
      this.data.account = null;
      this.product_quantity = 1;
      this.selectedItem = {};
      this.selectedAccount = {};
      this.itemToEdit = {};
      this.resetKey++;
    },

    initEditItem(item) {
      const itemEdit = this.productsInTable.find(
        (elem) => elem.value.Codigo == item.code
      );
      this.itemToEdit = item;
      this.inEditingAction = true;
      this.product_quantity = item.product_quantity;
      this.selectedItem = itemEdit.value;
      this.selectedAccount = item.account;
      this.resetKey++;
    },

    deleteItem(item) {
      this.tableItems = this.tableItems.filter((elem) => elem.id !== item.id);
      this.rowNumber = 1;
      this.tableItems.forEach((elem) => {
        elem.idTable = this.rowNumber;
        this.rowNumber++;
      });
    },
  },
};
</script>

<style>
.error-class {
  color: #ef5350;
  font-size: 12px;
}
.row-btn {
  display: flex;
  flex-direction: row;
}
</style>
