<template>
    <IndexInvoices :countryID="countryID" :key="$route.fullPath"/>
</template>
  
  <script>
  import IndexInvoices from "@/components/Invoices/IndexInvoices";
  export default {
    components: {
      IndexInvoices,
    },
    props: {
      countryID: {
        type: Number,
        required: true,
      },
    },
  };
  </script>
  
  <style>
  </style>