<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-4 ml-3"
        style="font-size: 26px; align-items: center; display: flex;"
      >
        <v-icon right style="margin-right: 14px; color: #625fa4;"
          >mdi-newspaper</v-icon
        >
        {{ action == 1 ? "Editar cliente" : "Crear cliente" }}
      </div>
      <v-col cols="12">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-text>
              <v-form>
                <div
                  style="font-size: 21px; font-weight: 500; margin-bottom: 4px;"
                >
                  Datos principales
                </div>
                <div style="font-size: 14px; margin-bottom: 20px;">
                  Completá los campos obligatorios.
                </div>
                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="typeDocValidation"
                    >
                      <v-select
                        :items="countryID === 2 ? type_docs : type_docs_arg"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Tipo de documento"
                        name="typeDoc"
                        color="blue-grey"
                        v-model="typeDoc"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="documentNumberValidation"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        type="number"
                        label="Número de documento"
                        name="documentNumber"
                        color="blue-grey"
                        v-model="documentNumber"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="companyNameValidation"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Razón social"
                        name="companyName"
                        color="blue-grey"
                        v-model="companyName"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider name=" " v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Nombre de Fantasía"
                        name="fantasyName"
                        color="blue-grey"
                        v-model="fantasyName"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      rules="required"
                      v-slot="{ errors }"
                      ref="taxConditionValidation"
                    >
                      <v-select
                        :items="
                          countryID === 2 ? tax_condition : tax_condition_arg
                        "
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Categoría impositiva"
                        name="taxCondition"
                        color="blue-grey"
                        v-model="taxCondition"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider name=" " v-slot="{ errors }">
                      <v-select
                        :items="countryID === 2 ? type_user : type_user_arg"
                        item-text="title"
                        item-value="value"
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Personería"
                        name="typeUser"
                        color="blue-grey"
                        v-model="typeUser"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider name=" " v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Giro"
                        name="giro"
                        color="blue-grey"
                        v-model="giro"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      v-slot="{ errors }"
                      rules="email"
                    >
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Email"
                        name="email"
                        color="blue-grey"
                        v-model="email"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>
            <v-card-text>
              <v-form>
                <div
                  style="font-size: 21px; font-weight: 500; margin-bottom: 4px;"
                >
                  Domicilio
                </div>
                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      v-slot="{ errors }"
                      rules="required"
                      ref="countryValidation"
                    >
                      <v-autocomplete
                        :items="countries"
                        required
                        item-text="value.label"
                        item-value="value"
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        role="button"
                        :loading="countriesLoading"
                        label="País"
                        name="country"
                        color="blue-grey"
                        v-model="country"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      v-slot="{ errors }"
                      rules="required"
                      ref="provinceValidation"
                    >
                      <v-autocomplete
                        :items="provinces"
                        :loading="provincesLoading"
                        required
                        item-text="value.label"
                        item-value="value"
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        role="button"
                        label="Provincia"
                        name="province"
                        color="blue-grey"
                        v-model="province"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      v-slot="{ errors }"
                      ref="cityValidation"
                    >
                      <v-autocomplete
                        :items="cities"
                        :loading="citiesLoading"
                        required
                        item-text="value.label"
                        item-value="value"
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        role="button"
                        label="Ciudad"
                        name="city"
                        color="blue-grey"
                        v-model="city"
                        :error-messages="errors"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="3" md="3">
                    <ValidationProvider name=" " v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Código postal"
                        name="zip"
                        color="blue-grey"
                        v-model="zip"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="3" md="3">
                    <ValidationProvider name=" " v-slot="{ errors }">
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Piso/Dep"
                        name="apartment"
                        color="blue-grey"
                        v-model="apartment"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name=" "
                      v-slot="{ errors }"
                      rules="numeric"
                    >
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Teléfono"
                        name="phone"
                        color="blue-grey"
                        v-model="phone"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-2"
                  :color="hover ? 'error' : 'grey'"
                  :disabled="loading"
                  @click="closeForm"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8 mx-2"
                  @click="passes(CreateClient)"
                  >{{ action == 1 ? "Editar cliente" : "Crear cliente" }}</v-btn
                >
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    closeForm: {
      type: Function,
      required: true,
      default: () => {},
    },
    countryID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      typeDoc: "",
      documentNumber: "",
      companyName: "",
      fantasyName: "",
      taxCondition: "",
      typeUser: "",
      email: "",
      country: "",
      province: "",
      city: "",
      zip: "",
      apartment: "",
      phone: "",
      giro: "",
      countries: [],
      provinces: [],
      cities: [],
      countriesLoading: false,
      provincesLoading: false,
      citiesLoading: false,
      type_docs: [
        {
          title: "Registro único tributario",
          value: "RUT",
        },
        {
          title: "Pasaporte",
          value: "PAS",
        },
        {
          title: "Cédula de identidad",
          value: "CI",
        },
      ],
      type_docs_arg: [
        {
          title: "DNI",
          value: "DNI",
        },
        {
          title: "CUIT",
          value: "CUIT/CUIL",
        },
      ],
      tax_condition: [
        {
          title: "Empresa",
          value: "EMPCL",
        },
        {
          title: "Consumidor Final",
          value: "CF",
        },
        {
          title: "Exterior",
          value: "EXT",
        },
      ],
      tax_condition_arg: [
        {
          title: "Responsable Inscripto",
          value: "RI",
        },
        {
          title: "Consumidor Final",
          value: "CF",
        },
        {
          title: "Monotributista",
          value: "MO",
        },
        {
          title: "Exento",
          value: "EX",
        },
        {
          title: "IVA No alcanzado",
          value: "NA",
        },
      ],
      type_user: [
        {
          title: "Natural",
          value: "F",
        },
        {
          title: "Jurídica",
          value: "J",
        },
      ],
      type_user_arg: [
        {
          title: "Física",
          value: "F",
        },
        {
          title: "Jurídica",
          value: "J",
        },
      ],
    };
  },

  created() {
    this.getCountries();
  },

  watch: {
    country(val) {
      this.province = "";
      this.provinces = [];
      this.getProvinces(val);
    },
    province(val) {
      this.city = "";
      this.cities = [];
      if (val) {
        this.getCities(val);
      }
    },
  },

  methods: {
    async getCountries() {
      try {
        this.countriesLoading = true;
        const request = {
          flextd_country_id: this.countryID,
        };
        const response = await this.$store.dispatch(
          "orders/GET_COUNTRIES",
          request
        );
        this.countries = response.data.map((val) => {
          return { value: { id: val.ID, label: val.Nombre } };
        });
        this.country = this.countries.filter(
          ({ value }) => value.id === (this.countryID === 1 ? 10 : 33)
        )[0].value;
      } catch (error) {
        console.log(error);
      } finally {
        this.countriesLoading = false;
      }
    },

    async getProvinces(val) {
      try {
        this.provincesLoading = true;
        const request = {
          flextd_country_id: this.countryID,
          contabilium_country_id: val.id,
        };
        const response = await this.$store.dispatch(
          "orders/GET_PROVINCES",
          request
        );
        this.provinces = response.data.map((val) => {
          return { value: { id: val.ID, label: val.Nombre } };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.provincesLoading = false;
      }
    },

    async getCities(val) {
      try {
        this.citiesLoading = true;
        const request = {
          country_id: this.countryID,
          contabilium_province_id: val.id,
        };
        const response = await this.$store.dispatch(
          "orders/GET_CITIES",
          request
        );
        this.cities = response.data.map((val) => {
          return { value: { id: val.ID, label: val.Nombre } };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.citiesLoading = false;
      }
    },

    checkRequiredFields() {
      this.$refs.typeDocValidation.validate();
      this.$refs.documentNumberValidation.validate();
      this.$refs.companyNameValidation.validate();
      this.$refs.taxConditionValidation.validate();
      this.$refs.countryValidation.validate();
      this.$refs.provinceValidation.validate();

      return (
        this.typeDoc &&
        this.documentNumber &&
        this.companyName &&
        this.taxCondition &&
        this.country &&
        this.province
      );
    },

    async CreateClient() {
      if (this.checkRequiredFields()) {
        try {
          this.loading = true;
          const request = {
            flextd_country_id: this.countryID,
            fullname: this.companyName,
            iva_condition: this.taxCondition,
            document_type: this.typeDoc,
            document_number: Number(this.documentNumber),
            country: {
              contabilium_id: this.country.id,
              contabilium_name: this.country.label,
            },
            province_contabilium_id: this.province.id,
            billing_address: this.city.label,
            phone: this.phone,
            email: this.email,
            billing_department_number: null,
            billing_zip_code: null,
            city_id: null,
            observations: "",
            giro: this.giro,
          };
          await this.$store.dispatch("orders/CREATE_CLIENT", request);
          this.$snotify.success(`Cliente creado`, "Exitos!");
          this.closeForm();
        } catch (error) {
          this.$snotify.error(error.response.data.message, "Error!");
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style></style>
