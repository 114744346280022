<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            v-if="status_card && !activeCreateClient"
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({})"
          >
            Crear Factura
          </v-btn>
          <v-btn
            v-if="status_card && !activeCreateClient"
            class="accent shadow-4 px-8 ml-4"
            large
            rounded
            @click.stop="HandlerCreateClient()"
          >
            Crear Cliente
          </v-btn>
          <register-component
            v-if="activeAddData"
            :active="activeAddData"
            :data="dataAdd"
            :action="actionAdd"
            :country="dataCountry"
            :countryID="countryID"
            @dialog:change="HandlerResponse"
            :closeModal="HandlerResponse"
          />
        </v-col>
      </v-row>
    </v-container>
    <create-client
      v-if="activeCreateClient"
      :countryID="countryID"
      @dialog:change="HandlerResponse"
      :closeForm="HandlerResponse"
    />
    <div v-if="status_card">
      <v-card class="shadow-1 mt-5">
        <v-col cols="12" sm="12" md="12" class="px-0 py-0 mt-8 rounded-small">
          <v-data-table
            :loading="false"
            :headers="headers"
            :items="tableItems"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
            style="background-color:transparent !important;"
          ></v-data-table>
        </v-col>
      </v-card>
    </div>
  </section>
</template>

<script>
import Register from "./Register";
import CreateClient from "./CreateClient.vue";

export default {
  components: {
    "register-component": Register,
    "create-client": CreateClient,
  },
  props: {
    countryID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingData: false,
      activeAddData: false,
      activeCreateClient: false,
      actionAdd: 0,
      status_card: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      country_id: "",
      state_id: "",
      location_id: "",
      loadingBtn: false,
      loadingState: false,
      loadingLocation: false,
      stateData: [],
      locationData: [],
      headers: [
        {
          text: "Número",
          sortable: false,
          value: "",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Cliente",
          sortable: false,
          value: "",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Moneda",
          sortable: false,
          value: "",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Importe",
          sortable: false,
          value: "",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Total Fact.",
          sortable: false,
          value: "bonus",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "ivaTable",
          align: "center",
          class: "primary--text",
        },
      ],
      tableItems: [],
      data: [],
      dataCountry: [],
    };
  },

  created() {
    this.HandlerGetDeposits();
    this.HandlerGetCountry();
  },

  watch: {
    page(val) {
      this.HandlerGetDeposits(val);
    },
    country_id(val) {
      this.HandlerGetState(val);
    },
    state_id(val) {
      this.HandlerGetLocations(val);
    },
  },

  computed: {
    getDeposits() {
      return this.$store.getters["deposits/GET_DEPOSITS"];
    },
    getPaginate() {
      return this.$store.getters["deposits/GET_PAGINATE"];
    },
  },

  methods: {
    CloseModal() {
      this.activeAddData = false;
    },
    async HandlerGetDeposits(page) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: true,
          country_id: this.country_id,
          state_id: this.state_id,
          location_id: this.location_id,
        };
        await this.$store.dispatch("deposits/GET_DEPOSITS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetState(item) {
      try {
        this.loadingState = true;
        const request = { country_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_STATE",
          request
        );
        this.stateData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingState = false;
      }
    },

    async HandlerGetLocations(item) {
      try {
        this.loadingLocation = true;
        const request = { state_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_LOCATIONS",
          request
        );
        this.locationData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingLocation = false;
      }
    },

    HandlerAddData(item) {
      this.activeAddData = true;
      this.actionAdd = 0;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    HandlerCreateClient() {
      this.activeCreateClient = true;
      this.status_card = false;
    },

    HandlerResponse() {
      this.activeCreateClient = false;
      this.activeAddData = false;
      this.status_card = true;
    },
  },
};
</script>

<style></style>
