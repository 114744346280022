var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('div',{staticClass:"font-weight-medium primary--text mb-4 ml-3",staticStyle:{"font-size":"26px","align-items":"center","display":"flex"}},[_c('v-icon',{staticStyle:{"margin-right":"14px","color":"#625fa4"},attrs:{"right":""}},[_vm._v("mdi-newspaper")]),_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar cliente" : "Crear cliente")+" ")],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"mb-5",attrs:{"height":"auto"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-text',[_c('v-form',[_c('div',{staticStyle:{"font-size":"21px","font-weight":"500","margin-bottom":"4px"}},[_vm._v(" Datos principales ")]),_c('div',{staticStyle:{"font-size":"14px","margin-bottom":"20px"}},[_vm._v(" Completá los campos obligatorios. ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"typeDocValidation",attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.countryID === 2 ? _vm.type_docs : _vm.type_docs_arg,"item-text":"title","item-value":"value","required":"","filled":"","rounded":"","dense":"","label":"Tipo de documento","name":"typeDoc","color":"blue-grey","error-messages":errors},model:{value:(_vm.typeDoc),callback:function ($$v) {_vm.typeDoc=$$v},expression:"typeDoc"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"documentNumberValidation",attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","type":"number","label":"Número de documento","name":"documentNumber","color":"blue-grey","error-messages":errors},model:{value:(_vm.documentNumber),callback:function ($$v) {_vm.documentNumber=$$v},expression:"documentNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"companyNameValidation",attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Razón social","name":"companyName","color":"blue-grey","error-messages":errors},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Nombre de Fantasía","name":"fantasyName","color":"blue-grey","error-messages":errors},model:{value:(_vm.fantasyName),callback:function ($$v) {_vm.fantasyName=$$v},expression:"fantasyName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"taxConditionValidation",attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.countryID === 2 ? _vm.tax_condition : _vm.tax_condition_arg,"item-text":"title","item-value":"value","required":"","filled":"","rounded":"","dense":"","label":"Categoría impositiva","name":"taxCondition","color":"blue-grey","error-messages":errors},model:{value:(_vm.taxCondition),callback:function ($$v) {_vm.taxCondition=$$v},expression:"taxCondition"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.countryID === 2 ? _vm.type_user : _vm.type_user_arg,"item-text":"title","item-value":"value","filled":"","rounded":"","dense":"","label":"Personería","name":"typeUser","color":"blue-grey","error-messages":errors},model:{value:(_vm.typeUser),callback:function ($$v) {_vm.typeUser=$$v},expression:"typeUser"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Giro","name":"giro","color":"blue-grey","error-messages":errors},model:{value:(_vm.giro),callback:function ($$v) {_vm.giro=$$v},expression:"giro"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":" ","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Email","name":"email","color":"blue-grey","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mb-5",attrs:{"height":"auto"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-text',[_c('v-form',[_c('div',{staticStyle:{"font-size":"21px","font-weight":"500","margin-bottom":"4px"}},[_vm._v(" Domicilio ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"countryValidation",attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"items":_vm.countries,"required":"","item-text":"value.label","item-value":"value","filled":"","rounded":"","dense":"","role":"button","loading":_vm.countriesLoading,"label":"País","name":"country","color":"blue-grey","error-messages":errors},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"provinceValidation",attrs:{"name":" ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"items":_vm.provinces,"loading":_vm.provincesLoading,"required":"","item-text":"value.label","item-value":"value","filled":"","rounded":"","dense":"","role":"button","label":"Provincia","name":"province","color":"blue-grey","error-messages":errors},model:{value:(_vm.province),callback:function ($$v) {_vm.province=$$v},expression:"province"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{ref:"cityValidation",attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"items":_vm.cities,"loading":_vm.citiesLoading,"required":"","item-text":"value.label","item-value":"value","filled":"","rounded":"","dense":"","role":"button","label":"Ciudad","name":"city","color":"blue-grey","error-messages":errors},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Código postal","name":"zip","color":"blue-grey","error-messages":errors},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":" "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Piso/Dep","name":"apartment","color":"blue-grey","error-messages":errors},model:{value:(_vm.apartment),callback:function ($$v) {_vm.apartment=$$v},expression:"apartment"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":" ","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Teléfono","name":"phone","color":"blue-grey","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card',[_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey',"disabled":_vm.loading},on:{"click":_vm.closeForm}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8 mx-2",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":function($event){return passes(_vm.CreateClient)}}},[_vm._v(_vm._s(_vm.action == 1 ? "Editar cliente" : "Crear cliente"))])]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }